import React from "react";
import { render } from "react-dom";
import _ from "lodash";
import "./index.css";

// This sample uses React Table
// Check it out at https://react-table.js.org/#/story/readme
import ReactTable from "react-table";
import "react-table/react-table.css";

// Import Azure Storage Blob SDK
import { Aborter, ServiceURL, ContainerURL, StorageURL, AnonymousCredential } from "@azure/storage-blob";

// Account name, and the container to list from
const account = 'grabbadocs'
const container = 'docs'
const containerLink = 'https://' + account +  '.blob.core.windows.net/' + container + '/'


class App extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [],
            pages: 2,
            markers: [],
            loading: true,
            prefix: "",
            products: []
        };
        this.fetchData = this.listBlobs.bind(this);
    }

    componentDidMount() {
        this.listProductImages(this.state)
            .then((res)=>{
                this.setState({ products: res});
                // console.log(`Products ${res.toString()}`);
            });
    }
  
    // TODO: Refactor this to avoid duplicate code
    async listProductImages(state)
    {
        // Valid image extensions to search for
        const validExtensions = ["jpg", "jpeg", "png"];

        // Use AnonymousCredential since $web container is made a 'public container' 
        // and does not require authorization
        const anonymousCredential = new AnonymousCredential();
        const pipeline = StorageURL.newPipeline(anonymousCredential);
    
        const serviceURL = new ServiceURL(
            `https://${account}.blob.core.windows.net`,
            pipeline
        );
    
        // If you are using a SAS token, simply append to ContainerURL here. 
        // We will use anonymous access hence no SAS token
        const containerName = container //+ `?st=2018-11-06T06%3A15%3A24Z&se=2019-11-07T06%3A15%3A00Z&sp=rl&sv=2018-03-28&sr=c&sig=4vCT7aInDWRiypkuYlezN8dos0K2h2DvQ0pnNkMJSFs%3D`;
        const containerURL = ContainerURL.fromServiceURL(serviceURL, containerName);
        
        // Fetch the prefix in the query params to browse into folders
        const urlParams = new URLSearchParams(window.location.search);
        const prefix = urlParams.get('prefix'); // TODO: Can make this more efficient by adding a prefix to the product pictures

        // List objects from Blob storage using the prefix
        // Delimiter for virtual directories is a forward slash '/' here
        const result = await containerURL.listBlobFlatSegment (
            Aborter.none,
            state.markers[state.page],
            {
                maxresults: state.pageSize,
                prefix: prefix
            }
        );

        var products = new Array();
        for (const blob of result.segment.blobItems)
        {
            var blobExtension = blob.name.split(".").pop();
            if (validExtensions.some(el => blobExtension.includes(el)))
            {
                var productName = blob.name.split(".")[0]
                var productUrl = this.getBlobLink(productName + "/");
                var productImageUrl = this.getBlobLink(blob.name);
                

                // console.log(`Product image found with name: ${productName}, productUrl: ${productUrl} and product image url: ${productImageUrl}`);
                var product = new Object();
                product["productName"] = productName;
                product["productUrl"] = productUrl;
                product["productImageUrl"] = productImageUrl;
                product["blobExtension"] = blobExtension;

                products.push(product);
            }
        }

        return products;
    }

    listBlobs(state, instance) {
        // this lists Blobs in pages defined in state.pageSize
        this.setState({ loading: true });
    
        // Use AnonymousCredential since $web container is made a 'public container' 
        // and does not require authorization
        const anonymousCredential = new AnonymousCredential();
        const pipeline = StorageURL.newPipeline(anonymousCredential);
    
        const serviceURL = new ServiceURL(
            `https://${account}.blob.core.windows.net`,
            pipeline
        );
    
        // If you are using a SAS token, simply append to ContainerURL here. 
        // We will use anonymous access hence no SAS token
        const containerName = container //+ `?st=2018-11-06T06%3A15%3A24Z&se=2019-11-07T06%3A15%3A00Z&sp=rl&sv=2018-03-28&sr=c&sig=4vCT7aInDWRiypkuYlezN8dos0K2h2DvQ0pnNkMJSFs%3D`;
        const containerURL = ContainerURL.fromServiceURL(serviceURL, containerName);
        
        // Fetch the prefix in the query params to browse into folders
        const urlParams = new URLSearchParams(window.location.search);
        const prefix = urlParams.get('prefix');

        // List objects from Blob storage using the prefix
        // Delimiter for virtual directories is a forward slash '/' here
        containerURL.listBlobHierarchySegment (
            Aborter.none,
            "/",
            state.markers[state.page],
            {
                maxresults: state.pageSize,
                prefix: prefix
            }
        ).then(res => {
            // Store the nextMarker in an array for prev/next buttons only if there are more blobs to show
            const markers = state.markers.slice();
            var totalPages = state.page+1;
            if (res.nextMarker) {
                markers[(state.page+1)] = res.nextMarker;
                totalPages++;
            }
              
            // Combine the found virtual directories and files
            Array.prototype.push.apply(res.segment.blobItems, res.segment.blobPrefixes)

            // This is to sort rows, and handles blobName, contentLength and lastModified time
            const sortedData = _.orderBy(
                res.segment.blobItems,
                state.sorted.map(sort => {
                    return row => {
                        if (row[sort.id] === null) {
                            return -Infinity;
                        } // TODO: following is a workaround to special case contentLength and lastModified
                        else if(row[sort.id] === undefined){
                            if(row.properties === undefined)
                            {
                                return -Infinity;
                            } else {
                                return row.properties[sort.id];
                            }
                        }
                        return typeof row[sort.id] === "string"
                            ? row[sort.id].toLowerCase()
                            : row[sort.id];
                    };
                }),
                state.sorted.map(d => (d.desc ? "desc" : "asc"))
              );

            // Store the state
            this.setState({
                data: sortedData,
                pages: totalPages,
                markers: markers,
                loading: false,
                prefix: prefix
            });
    });
    }

    getBlobLink(blobName){
        var link;
        if(blobName === "../")
        {
            link = "/"
        }
        else if(blobName.slice(-1) === "/")
        {
            link = "?prefix=" + blobName
        } else {
            link = containerLink + blobName
        }
        return link;
    }

    // Custom links for various scenarios (handles blobs, directories and go back link)
    renderLink(blobName) {
        return (
            <a href={this.getBlobLink(blobName)}>
                {blobName}
            </a>
        );
    }

    renderTable()
    {
        const { data, pages, markers, loading, prefix, products } = this.state;

        // If this is a directory view, add a go back link for the root
        var dataset = data
        if(prefix !== null)
        {
            dataset = [{name: "../"}].concat(dataset);
        }

        return (
            <div>
            <ReactTable
                columns={[
                {
                    Header: "Name",
                    id: "name",
                    accessor: "name",
                    minHeight: 1,
                    Cell: row => (
                        this.renderLink(row.value)
                    )
                },
                {
                    Header: "Last Modified",
                    id: "lastModified",
                    accessor: (d) => {
                        if(typeof d.properties !== "undefined" ){
                            return d.properties.lastModified.toISOString()
                        }
                    },
                    maxWidth: 400
                },
                {
                    Header: "File Size (bytes)",
                    id: "contentLength",
                    accessor: (d) => {
                        if(typeof d.properties !== "undefined"){
                            return d.properties.contentLength
                        }
                    },
                    maxWidth: 200
                }
            ]}
            manual // Do not paginate as we can only list objects in pages from Blob storage
            data={dataset}
            pages={pages} 
            markers={markers}
            loading={loading} 
            onFetchData={this.fetchData} 
            defaultPageSize={10}
            getTheadProps={(state, rowInfo, column) =>  {
                return {
                    style: {
                        background: '#000A2C',
                        color: 'white'
                    }
                }
            }}
            className="-striped -highlight"
            />
            <div className="grabba-footer">
                    © 2010 - 2024 Grabba. All Rights Reserved
                    &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://grabba.com/legal/privacy-policy/" style={{color: "rgb(255,255,255,0.75)"}} target="_blank" rel="noopener">Privacy Policy</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://grabba.com/legal/warranty/" style={{color: "rgb(255,255,255,0.75)"}} target="_blank" rel="noopener">Warranty</a>
                    <span style={{float: 'right', margin: '0px 30px 0px 0px'}}>
                        <img src="grabba-logo-white.png" height='30' width='30'/>
                    </span>
                </div>
            </div>
        );
    }

    renderProductSelector()
    {
        const { data, pages, markers, loading, prefix, products} = this.state;

        return(
            <div>
                {products.map(({ productImageUrl, productUrl, productName }) => (
                    <a href={productUrl}>
                    <div class="card">
                    <img maxWidth="200" Height="200" key={productImageUrl} src={productImageUrl}/>
                    <div class="container">
                        <h4><b>{productName}</b></h4>
                    </div>
                    </div>
                    </a> 
                ))}
                <div className="grabba-footer">
                    © 2010 - 2024 Grabba. All Rights Reserved
                    &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://grabba.com/legal/privacy-policy/" style={{color: "rgb(255,255,255,0.75)"}} target="_blank" rel="noopener">Privacy Policy</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://grabba.com/legal/warranty/" style={{color: "rgb(255,255,255,0.75)"}} target="_blank" rel="noopener">Warranty</a>
                    <span style={{float: 'right', margin: '0px 30px 0px 0px'}}>
                        <img src="grabba-logo-white.png" height='30' width='30'/>
                    </span>
                </div>
            </div>
        )
    }

    render() {
        const { data, pages, markers, loading, prefix, products} = this.state;
        if(prefix == null)
        {
            // We're at the root of the blob, render the product selector
            return this.renderProductSelector();
        }
        else
        {
            // Else we can render the standard documentation table
            return this.renderTable();
        }
    }
}

render(<App />, document.getElementById("root"));